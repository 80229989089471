<template>
  <div class="about dark:bg-bggraym dark:text-gray-50">
    <section
      class="antialiased dark:bg-bggraym dark:text-gray-50 bg-gray-100 text-gray-600 min-h-screen h-auto px-4"
    >
      <div class="flex flex-col justify-center h-auto pt-16">
        <div
      v-if="!usersArr.length > 0"
        wire:loading
        class="fixed top-0 left-0 right-0 dark:bg-bggrayl dark:text-gray-50 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-300 opacity-75 flex flex-col items-center justify-center"
      >
        <div
          class="loader ease-linear rounded-full dark:bg-bggrayl dark:text-gray-50 border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"
        ></div>
      </div>
        <!-- Table -->
        <div v-else class="relative pt-3xl">
          <header class="w-full max-w-5xl mx-auto block md:flex">
            <h2 class="font-sans text-gray-800 flex text-4xl dark:text-gray-50 pb-3">Users</h2>
            <div class="flex items-end justify-end mb-5 w-full mx-auto">
              <div class="flex rounded-lg">
                <div class="container h-10 flex justify-center items-center">
                  <div class="relative">
                    <span
                      class="absolute inset-y-0 left-0 flex items-center pl-2"
                    >
                      <button
                        type="submit"
                        class="p-1 focus:outline-none focus:shadow-outline"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.59896 9.66669L16.1994 16.3067"
                            stroke="#222222"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.33333 11C8.91066 11 11 8.91068 11 6.33335C11 3.75602 8.91066 1.66669 6.33333 1.66669C3.75601 1.66669 1.66667 3.75602 1.66667 6.33335C1.66667 8.91068 3.75601 11 6.33333 11Z"
                            stroke="#222222"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </span>
                    <input
                      v
                      type="text"
                      v-model="search"
                      class="h-10 text-base border-myblack border w-d pl-9 pr-8 rounded-lg z-0 focus:outline-none"
                      placeholder="Search by email or user UID"
                    />
                    <div class="absolute top-2 right-2">
                      <button
                        v-if="search"
                        @click="clearResult"
                        class="h-6 w-6 text-myblack rounded-lg bg-transparent"
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  class="px-4 text-base text-gray-50 font-sans px-7 rounded-full ml-5 bg-mygreen border"
                  @click="searchChange"
                >
                  Search
                </button>
              </div>
            </div>
          </header>
          <div
            class="w-full max-w-5xl mx-auto bg-mywhite dark:bg-bggrayl dark:text-gray-50 shadow-lg rounded-sm border border-gray-200"
          >
            <div class="p-3">
              <div class="overflow-x-auto">
                <table class="table-auto w-full divide-y divide-mygray ">
                  <thead class="text-base font-semibold text-black dark:bg-bggrayl dark:text-gray-50 bg-mywhite">
                    <tr>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-serif text-left">Email</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-serif text-left">Created</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-serif text-left">Last Opened</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-serif text-left">User UID</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="user in usersArr"
                    :key="user.id"
                    class="text-sm divide-y divide-gray-300 font-serif"
                  >
                    <tr>
                      <td class="p-2 whitespace-nowrap font-bold">
                        <router-link
                          :to="{ name: 'UserDetail', params: { id: user.id } }"
                        >
                          <div class="flex items-center">
                            <div
                              class="font-medium font-bold"
                              v-if="user.email"
                            >
                              {{ user.email }}
                            </div>
                            <div class="font-bold" v-else>(anonymous)</div>
                          </div>
                        </router-link>
                      </td>
                      <td class="p-2 whitespace-nowrap font-normal">
                        <router-link
                          :to="{ name: 'UserDetail', params: { id: user.id } }"
                        >
                          <div class="text-left">
                            {{ convertDate(user.createdAt) }}
                          </div>
                        </router-link>
                      </td>
                      <td class="p-2 whitespace-nowrap">
                        <router-link
                          :to="{ name: 'UserDetail', params: { id: user.id } }"
                        >
                          <div
                            class="text-left font-normal dark:text-gray-50 text-gray-800"
                            v-if="user.lastSignInDate"
                          >
                            {{ convertDate(user.lastSignInDate) }}
                          </div>
                          <div
                            class="text-left font-normal text-gray-800"
                            v-else
                          >
                            -
                          </div>
                        </router-link>
                      </td>
                      <td class="p-2 whitespace-nowrap font-normal">
                        <router-link
                          :to="{ name: 'UserDetail', params: { id: user.id } }"
                        >
                          <div class="text-l text-left">{{ user.id }}</div>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div size="2xl" class="w-full mt-7 max-w-5xl mx-auto rounded-sm mb-16">
          <div class="flex justify-end">
            <button
              class="font-bold mr-3 rounded-full bg-transparent bg-mygreen border-mygreen border-2 flex items-center justify-center text-mywhite font-mono disabled:bg-mywhite"
              style="height: 50px; width: 50px; font-size: 25px"
              :disabled="prev_btn"
              @click="previous"
            >
              <img
                v-if="prev_btn == true"
                class="w-4 pr-1"
                src="../assets/chevron.png"
              />
              <img v-else class="w-4 pr-1" src="../assets/chevronwhiter.png" />
            </button>
            <button
              class="font-bold ml-3 rounded-full bg-transparent bg-mygreen border-mygreen border-2 flex items-center justify-center text-mywhite font-mono"
              style="height: 50px; width: 50px; font-size: 25px"
              :disabled="next_btn"
              @click="next"
            >
              <img
                v-if="next_btn == false"
                class="w-4 pl-1"
                src="../assets/chevronwhitel.png"
              />
              <img v-else class="w-4 pl-1" src="../assets/chevronright.png" />
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import getCollection from "@/composables/getCollection";
import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";
import { DateTime } from "luxon";
import firebase from "firebase/compat";
import { db } from "../firebase/config";

export default {
  data() {
    return {
      fullArr: [],
      usersArr: [],
      filteredArr: [],
      limit: 100,
      lengthArr: null,
      lastVisible: "",
      firstVisible: "",
      search: "",
      next_btn: false,
      prev_btn: true,
    };
  },
  setup() {
    const { logout } = useLogout();
    const router = useRouter();
    const handleClick = async () => {
      await logout();
      console.log("logged out");
      router.push({ name: "Login" });
    };
    const { documents: users } = getCollection("Users");
    console.log();
    return { handleClick, users };
  },
  async created() {
    this.usersArr = await db
      .collection("Users")
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
    this.lengthArr = this.usersArr.length;
    db.collection("Users")
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((documentSnapshots) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.firstVisible = documentSnapshots.docs[0];
      })
      .then(() => {
        db.collection("Users")
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((snap) => {
            if (snap.size === 0) {
              this.next_btn = true;
            }
          });
      });
  },
  methods: {
    async clearResult() {
      this.search = "";
      this.usersArr = await db
        .collection("Users")
        .orderBy("createdAt", "desc")
        .limit(this.limit)
        .get()
        .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
    },
    /*     searchChange() {
      if (this.search) {
        if (this.search.includes("@")) {
          this.usersArr = [];
          this.fullArr.forEach((collection) => {
            if (
              collection.email != undefined &&
              collection.email
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
            ) {
              this.usersArr.push(collection);
            }
          });
        }
        if (!this.search.includes("@")) {
          this.usersArr = [];
          this.fullArr.forEach((collection) => {
            if (
              collection.id != undefined &&
              collection.id
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
            ) {
              this.usersArr.push(collection);
            }
          });
        }
      } else {
        console.log(this.usersArr);
      }
    }, */
    async searchChange() {
      if (this.search) {
        if (this.search.includes("@")) {
          this.filteredArr = await db
            .collection("Users")
            .where("email", "==", this.search.toLowerCase().trim())
            .get()
            .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
          this.usersArr = this.filteredArr;
        }
        if (!this.search.includes("@")) {
          this.filteredArr = await db
            .collection("Users")
            .where(
              firebase.firestore.FieldPath.documentId(),
              "==",
              this.search.trim()
            )
            .get()
            .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
          this.usersArr = this.filteredArr;
        }
      } else {
        console.log(this.usersArr);
      }
    },
    convertDate(e) {
      return DateTime.fromMillis(e).toFormat("yyyy LLL dd");
    },
    async next() {
      if (!this.next_btn) {
        this.usersArr = await db
          .collection("Users")
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
        db.collection("Users")
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((documentSnapshots) => {
            this.lastVisible =
              documentSnapshots.docs[documentSnapshots.docs.length - 1];
            this.firstVisible = documentSnapshots.docs[0];
          })
          .then(() => {
            db.collection("Users")
              .orderBy("createdAt", "desc")
              .startAfter(this.lastVisible)
              .limit(this.limit)
              .get()
              .then((snap) => {
                if (snap.size === 0) {
                  this.next_btn = true;

                  this.prev_btn = false;
                } else {
                  this.next_btn = false;

                  this.prev_btn = false;
                }
              });
          });
      }
    },
    async previous() {
    this.usersArr = await db
      .collection("Users")
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((m) => m.docs.map((m) => ({ id: m.id, ...m.data() })));
    this.lengthArr = this.usersArr.length;
    db.collection("Users")
      .orderBy("createdAt", "desc")
      .limit(this.limit)
      .get()
      .then((documentSnapshots) => {
        this.lastVisible =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];
        this.firstVisible = documentSnapshots.docs[0];
      })
      .then(() => {
        db.collection("Users")
          .orderBy("createdAt", "desc")
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((snap) => {
            if (snap.size === 0) {
              this.next_btn = true;
            }
          });
      });
    }
  },
};
</script>
<style>
.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.w-d {
  width: 315px;
}

button:disabled,
button[disabled] {
  background-color: transparent;
  color: green;
}
@media screen and (max-width: 780px) {
  .w-d {
    width: 100%;
  }
}
</style>
